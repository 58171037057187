<template>
    <div>
        <card-header title="Edit Day Photo" icon="fa-pencil"/>
        <card-body>
            <img alt="day" :src="apiUrl + 'api/day_photos/img/' + photo.file + '/day'">
            <card-list>
                <day-photo-form :card="card" :photo="photo"/>

                <subheader-list-item title="Files" icon="fa-images"/>
                <container-list-item>
                    <data-row title="Original"><a :href="apiUrl + 'api/day_photos/img/' + photo.file" target="_blank"><i class="fas fa-cloud-download mr-1"></i>Download</a></data-row>
                    <data-row title="Thumbnail"><a :href="apiUrl + 'api/day_photos/img/' + photo.file + '/day'" target="_blank"><i class="fas fa-cloud-download mr-1"></i>Download</a></data-row>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deletePhoto"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Photo?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Photo</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import PhotoForm from "@/cards/trips/PhotoForm";

    import {client as http} from "../../http_client";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import DataRow from "@/TIER/components/DataRow";
    import DayPhotoForm from "@/cards/trips/DayPhotoForm";

    export default {
        components: {DayPhotoForm, DataRow, ContainerListItem, SubheaderListItem, CardToolbar, PhotoForm, CardList, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function() {
            return {
                photo: {},
                confirmDelete: false,
                dirty: null,
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/',
            };
        },
        methods: {
            loadPhoto: function() {
                this.$emit('loading', true);
                http.get('/api/day_photos/' + this.props.photoId, {force: true}).then(response => {
                    this.photo = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/day_photos/' + this.props.photoId, this.photo, {force: true}).then(response => {
                    console.log({tripId: this.photo.trip_id});
                    this.$reloadCard('day', {dayId: this.photo.day_id});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deletePhoto() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);
                    http.delete('/api/day_photos/' + this.photo.id).then(response => {
                        this.$reloadCard('day', {dayId: this.photo.day_id});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.photoId': function() {
                this.dirty = null;
                this.loadPhoto();
            },
            'photo': {
                deep: true,
                handler: function() {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this photo? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadPhoto();
        }
    };
</script>

<style scoped>

</style>
